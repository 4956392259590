export const queryFragmentEducationEntryThumbail = {
  educationEntry: {
    fields: ['title', 'slug', 'locale'],
    populate: {
      localizations: {
        fields: ['title', 'slug', 'locale'],
      },
      coverImage: '*',
    }
  }
}

export const queryFragmentEventEntryThumbail = {
  eventEntry: {
    fields: ['title', 'slug', 'locale'],
    sort: ['sortKeyAsc:desc', 'sortKeyDesc:asc', 'title:asc'],
    populate: {
      localizations: {
        fields: ['title', 'slug', 'locale'],
      },
      dateTimeRanges: true,
      eventCategories: true,
      coverImage: true
    }
  }
}

export const queryFragmentExhibitionEntryThumbnail = {
  exhibitionEntry: {
    fields: ['title', 'slug', 'locale', 'dateStart', 'dateEnd', 'externalLocation'],
    populate: {
      localizations: {
        fields: ['title', 'slug', 'locale', 'dateStart', 'dateEnd', 'externalLocation'],
      },
      coverImage: '*',
    }
  }
}

export const queryFragmentJournalEntryThumbnail = {
  journalEntry: {
    fields: ['title', 'slug', 'locale', 'datePosted'],
    populate: {
      localizations: {
        fields: ['title', 'slug', 'locale', 'datePosted'],
      },
      coverImage: '*',
      blocks: {
        ...queryFragmentFirstRichTextBlock
      },
      journalTags: '*'
    }
  }
}

export const queryFragmentPageEntryThumbnail = {
  pageEntry: {
    fields: ['title', 'slug', 'locale'],
    populate: {
      localizations: {
        fields: ['title', 'slug', 'locale'],
      },
    }
  }
}

export const queryFragmentProjectEntryThumbnail = {
  projectEntry: {
    fields: ['title', 'slug', 'locale'],
    populate: {
      localizations: {
        fields: ['title', 'slug', 'locale'],
      },
      coverImage: '*',
    }
  }
}
