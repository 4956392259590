<template>
  <div :class="$style.root">
    <PagesIndexNoteComponent
      v-if="page?.note"
      :note="page?.note"
      :noteLink="page?.noteLink"
      :class="$style.noteContainer"
    />

    <LayoutGridComponent>
      <template
        v-for="(block, index) in blocks"
        :key="index"
      >
        <PagesIndexEventsComponent
          v-if="block?.__component === 'index.events' && dataEventEntries"
          :dataEventEntries="dataEventEntries"
        />

        <SharedExhibitionsThumbnailComponent
          v-else-if="block?.__component === 'index.exhibition' && block?.exhibitionEntry?.data?.attributes"
          :data="substituteCoverImage(block?.exhibitionEntry?.data, block?.coverImage)"
        />

         <SharedJournalsThumbnailComponent
          v-else-if="block?.__component === 'index.journal' && block?.journalEntry?.data?.attributes"
          :data="substituteCoverImage(block?.journalEntry?.data, block?.coverImage)"
        />

        <PagesIndexEventComponent
          v-else-if="block?.__component === 'index.event' && block?.eventEntry?.data?.attributes"
          :data="substituteCoverImage(block?.eventEntry?.data, block?.coverImage)"
        />

        <div
          v-else-if="block?.__component === 'index.rich-text' && block?.richText"
          :class="$style.text"
        >
          <CoreTextComponent
            :string="block?.richText"
          />
        </div>

        <PagesIndexThumbnailComponent
          v-else-if="
                     block?.__component === 'index.page' && block?.pageEntry?.data?.attributes ||
                     block?.__component === 'index.education' && block?.educationEntry?.data?.attributes ||
                     block?.__component === 'index.project' && block?.projectEntry?.data?.attributes
                    "
          :data="substituteCoverImage(dataForBlock(block), block?.coverImage)"
          :routeName="routeNameForBlock(block?.__component)"
          :i18nPageName="i18nPageName(block?.__component)"
        />

        <PagesIndexNewsletterComponent
          v-else-if="block?.__component === 'index.newsletter'"
        />

        <CoreImageComponent
          v-else-if="block?.__component === 'index.image' && block?.image?.attributes"
          :image="block?.image?.attributes"
        />
      </template>
    </LayoutGridComponent>
  </div>
</template>

<script lang="ts" setup>
import { sample } from 'lodash-es'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  dataEventEntries: {
    object: Array,
    required: false
  }
})

// 1
const page = baseStrapiGetAttributes(props.data)
const eventsBlockIndex = page?.blocks?.findIndex((block) => block?.__component === 'index.events' && props?.dataEventEntries?.data?.length > 0)
const hasEventsBlock = eventsBlockIndex > -1
let blocks = page?.blocks
let layoutBlocksCount = blocks?.length || 0

if (eventsBlockIndex > 0) {
  layoutBlocksCount++
}

if (!hasEventsBlock && layoutBlocksCount % 2 !== 0) {
  blocks = [
    ...blocks,
    { __component: 'index.image', image: sample(page?.images?.data) }
  ]
}

if (hasEventsBlock && eventsBlockIndex < layoutBlocksCount) {
  const indexesAfterEventsBlock = layoutBlocksCount - eventsBlockIndex

  if (eventsBlockIndex % 2 !== 0) {
    blocks = [
      ...blocks.slice(0, eventsBlockIndex),
      { __component: 'index.image', image: sample(page?.images?.data) },
      ...blocks.slice(eventsBlockIndex)
    ]
  }

  if (indexesAfterEventsBlock % 2 !== 0) {
    blocks = [
      ...blocks,
      { __component: 'index.image', image: sample(page?.images?.data) }
    ]
  }
}

// 2
const dataForBlock = (block) => {
  switch (block?.__component) {
    case 'index.page':
      return block?.pageEntry?.data
    case 'index.education':
      return block?.educationEntry?.data
    case 'index.project':
      return block?.projectEntry?.data
  }
}

const routeNameForBlock = (component) => {
  switch (component) {
    case 'index.page':
      return 'slug'
    case 'index.education':
      return 'education-slug'
    case 'index.project':
      return 'projects-slug'
  }
}

const i18nPageName = (component) => {
  switch (component) {
    case 'index.education':
      return 'education'
    case 'index.project':
      return 'projects'
    case 'index.event':
      return 'events'
    default:
      return null
  }
}

const substituteCoverImage = (block = {}, coverImage = {}) => {
  if (coverImage?.data?.id && block) {
    return {
      ...block,
      attributes: {
        ...block?.attributes,
        coverImage: coverImage
      }
    }
  } else {
    return block
  }
}
</script>

<style module>
.root {
}

.text {
  composes: font-size-large from global;

  padding: var(--unit--default);

  margin: 0 auto;

  align-self: center;
  text-align: center;

  max-width: var(--container--width);
}

.noteContainer {
  composes: container font-size-x-large from global;
  margin-bottom: var(--unit--vertical);
}

.root:has([data-event-annoucements]) .noteContainer {
  margin-bottom: 0;
}
</style>
