<template>
  <div :class="$style.root">
    <i18nLink
      v-if="attributes?.slug"
      :to="{ name: routeName, params: { slug: attributes?.slug } }"
      :locale="i18nCode"
      :class="$style.link"
    >
      <LayoutImageWithCaptionComponent>
        <template #image>
          <CoreImageComponent
            v-if="attributes?.coverImage?.data?.attributes"
            :image="attributes?.coverImage?.data?.attributes"
            :class="$style.coverImage"
            :showCaption="false"
            :data-orientation="getOrientation(attributes?.coverImage?.data?.attributes)"
          />
          <div
            v-else
            :class="$style.coverImagePlaceholder"
          >
            {{ title }}
          </div>
        </template>

        <template #caption>
          <span
            v-if="i18nPageName"
            :class="$style.type"
          >
            {{ $t(`pages.${i18nPageName}.label`)?.toLowerCase() }}
          </span>
          &VeryThinSpace;
          <span :class="$style.title">{{ title }}</span>
        </template>
      </LayoutImageWithCaptionComponent>
    </i18nLink>
  </div>
</template>

<script lang="ts" setup>
  const props = defineProps({
    data: Object,
    routeName: String,
    i18nPageName: String
  })

  const localizedData = baseStrapiGetLocalizedData(props.data)

  const attributes = baseStrapiGetAttributes(localizedData)
  const i18nCode = baseI18nGetCodeForIso(attributes?.locale)
  const truncate = (str: string, length: number = 60) => {
    return str?.length > length ? str?.substring(0, length - 3) + '...' : str
  }

  // NOTE extract to base, make part of component, also add square
  const getOrientation = (image) => {
    if (image?.width && image?.height) {
      return image.width > image.height ? 'landscape' : 'portrait'
    }
  }

  const title = baseTruncateString(attributes?.title, 14)

</script>

<style module>
  .root {
  }

  .link {
    composes: reset-link from global;
  }

  .heading {
  }

  .title {
    composes: font-helvetica font-size-small from global;
    text-decoration-line: underline;
  }


  .link:hover .title {
    text-decoration-line: none;
  }

  .type {
    composes: font-size-small font-helvetica label-inverted from global;
  }

  .coverImage {
    --base--image--object-fit: contain;
    --base--image--object-position: center;

    display: flex;
    justify-content: center;
  }

  .coverImage img {
  }

  .coverImage[data-orientation='portrait'] {
    --base--image--object-fit: cover;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .coverImagePlaceholder {
    composes: font-helvetica font-size-x-large from global;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 3 / 2;

    background-color: var(--color--gray);
    color: var(--color--black);
    overflow: hidden;

    padding: var(--padding--element);
  }

  .updatedAt {
    composes: font-helvetica font-size-small from global;
  }
</style>
