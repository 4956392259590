import qs from 'qs'

import dayjs from 'dayjs/esm/index.js'
import utc from 'dayjs/esm/plugin/utc'
import timezone from 'dayjs/esm/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const today = dayjs().tz('Europe/Amsterdam').format('YYYY-MM-DD')

const fields = [
  'title',
  'slug',
  'locale',
  'sortKeyAsc',
  'sortKeyDesc',
]
const sort = ['sortKeyAsc:asc', 'sortKeyDesc:desc', 'title:asc']
const populate = {
  eventCategories: true,
  dateTimeRanges: true,
  coverImage: '*'
}

export const queryEventEntriesThumbnailUpcoming = (options: QueryManyOptionsEventEntriesi18n) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'nl-NL',
      sort: sort,
      filters: {
        sortKeyAsc: { $gte: today }
      },
      pagination: {
        limit: 2,
      },
      fields: fields,
      populate: {
        ...populate,
        coverImage: '*',
        localizations: {
          sort: sort,
          fields: fields,
          populate: {
            ...populate,
          }
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `event-entries?${query}`
}
