<template>
  <div v-if="noteIncludesLink">
    <div>
      <span v-html="textBeforeLink" />
      <SharedLinkComponent
        :link="noteLink"
        :class="$style.link"
        v-if="hasInternalLink"
      >
        {{ noteLink?.label }}
      </SharedLinkComponent>
      <a
        v-else-if="hasExternalLink"
        :href="noteLink?.url"
        target="_blank"
      >
        {{ noteLink?.label }}
      </a>
      <span v-html="textAfterLink" />
    </div>
  </div>
  <CoreTextComponent
    v-else
    :string="props.note"
  />
</template>

<script lang="ts" setup>
const props = defineProps({
  note: {
    type: String,
    required: true
  },
  noteLink: {
    object: Object,
    required: false
  }
})

const noteIncludesLink = props?.note?.includes(props?.noteLink?.label)

const hasExternalLink = props?.noteLink && props?.noteLink?.url && props?.noteLink?.label && props?.note?.includes(props?.noteLink?.label)
const hasInternalLink = props?.noteLink && props?.note?.includes(props?.noteLink?.label)
const linkText = props?.noteLink?.label?.trim()
const text = props?.note?.replace(/(<([^>]+)>)/ig, '');
const chunks = text?.split(linkText)?.filter((chunk) => chunk !== '')

let textBeforeLink = chunks?.length > 1 ? chunks?.[0] : text?.startsWith(linkText) ? '' : chunks[0]
let textAfterLink = chunks?.length > 1 ? chunks?.[chunks?.length - 1] : text?.endsWith(linkText) ? '' : chunks[0]
</script>

<style module>
.link {
  composes: reset-link link from global;
  display: inline;
}
</style>
