import qs from 'qs'

export const queryHomePage = (locale: QueryLocaleString) => {
  const query = qs.stringify(
    {
      locale: locale || 'en-US',
      fields: [
        'title',
        'note'
      ],
      populate: {
        ...queryFragmentSeo,
        noteLink: {
          populate: queryFragmentLink
        },
        images: '*',
        blocks: {
          populate: {
            coverImage: true,
            ...queryFragmentEducationEntryThumbail,
            ...queryFragmentEventEntryThumbail,
            ...queryFragmentExhibitionEntryThumbnail,
            ...queryFragmentJournalEntryThumbnail,
            ...queryFragmentPageEntryThumbnail,
            ...queryFragmentProjectEntryThumbnail,
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `home-page?${query}`
}
