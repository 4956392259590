<template>
  <div :class="$style.root">
    <LayoutImageWithCaptionComponent>
      <template #image>
        <i18nLink :class="$style.linkSvg" :to="{name: 'newsletter'}">
          <SvgoNewsletterSignUp :class="$style.svg" />
        </i18nLink>
      </template>
      <template #caption>
        <i18nLink :class="$style.link" :to="{ name: 'newsletter' }">{{ $t('newsletter.prompt') }}</i18nLink>
      </template>
    </LayoutImageWithCaptionComponent>
  </div>
</template>

<style module>
.root {
  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}

.about {
  composes: font-size-large from global;
  text-align: center;
}

.link {
  composes: reset-link link from global;
}

.root:hover .link {
  text-decoration-line: none;
}

.linkSvg {
  composes: reset-link from global;

  background-color: #bdbdbd;

  display: flex;
  justify-content: center;
  aspect-ratio: 1.3847241867043847;
}

.svg {
  fill: var(--color--white);
  overflow: visible;

  width: calc(100% - var(--unit--vertical) * 2);

  transform: rotate(-2deg);
}
</style>
